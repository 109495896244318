/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "react-virtualized/styles.css";
import "./App.css";
import "./response.css"
import "./views/KBView/KBstyle.css";
import "./views/KBView/KBNewStyle.css";
import { CCol, CRow } from "@coreui/react";
import { Authcontext } from "../src/views/components/Context/Context";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import LogoKBIcon from "./assets/katbook_logo.png";
// import KBView from "./views/KBView/KBView";
import { TheContent } from "./containers";
import EmployeeContext from "./Services/EmployeeContext";
import GlobalContext from "./Services/GlobalContext";


import {
  getUserProfile,
  KampusLogin,
  getKampusStudentLogin,
  deleteKampusStudentLogin,
  getAllLog,
  loginIskconUser
} from "./Services/ApiService";
// import Model from "./views/components/Model/Model";
import UserLogModel from "./views/components/Model/UserLogModel";

import Danger from "./views/components/Toastify/Danger";
import Progress from "./views/components/Progress/Progress";
import Warning from "./views/components/Toastify/Warning";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
// Pages
const Login = React.lazy(() => import("./views/Login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

const removetranslateCookie = () => {
  document.cookie = "googtrans=";
}

const initialState = {
  isAuthenticated: false,
  katbookInitiation: "",
  userType: null,
  userName: "",
  ageGroup: "",
  grade: "",
  userCode: "",
  user: null,
  email: "",
  _id: "",
  token: null,
  userTypeId: "",
  userTypes: "",
  UserTypecode: "",
  route: "",
  subRoute: "",
  companyId: "6007cfc79052d71fec82fef2",
  companyName: "KATBOOK CONTENT DEVELOPMENT",
  institutionName: "",
  student: false,
  userLogger: false,
  institutionUser: false,
  log: "",
  loginUserType: false,
  url: "",
  teacherSelectedSection: "",
  teacherSelectedclass: "",
  leftBar: false,
  rightBar: false,
  stateColor: '#00356D'
};
const reducer = (state, action) => {
  switch (action.type) {
    case "Profile":
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      console.log(action,"ACTIONS LISTS");
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        userType: action.userType,
        UserTypecode: action.UserTypecode,
        userTypeId: action.userTypeId,
        userTypes: action.userTypes,
        institutionName: action.institutionName,
        student: action.student,
        standard: action.standard,
        section: action.section,
        refInstID: action.refInstID,
        variableID: action.variableID,
        loginUserType: action.loginUserType,
        url: action.url,
      };
    case "LOGIN":
      console.log("hihihihiii", action);
      console.log("hihihihiii", state);
      localStorage.setItem("token", action.token);
      localStorage.setItem("student", action.student);
      localStorage.setItem(
        "instDetail",
        JSON.stringify({ userName: action.user, password: action.password })
      );
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        _id: action._id,
        email: action.email,
        user: action.user,
        adminName: action.adminName,
        userType: action.userType,
        userName: action.userName,
        ageGroup: action.ageGroup,
        grade: action.userTypeId?.userType === "ADMIN" ? "Admin" : action.grade,
        userCode: action.userCode
        // UserTypecode: action.UserTypecode,
        // institutionName: action.institutionName,
        // userTypeId: action.userTypeId,
        // userTypes: action.userTypes,
        // student: action.student,
        // standard: action.standard,
        // section: action.section,
        // refInstID: action.refInstID,
        // variableID: action.variableID,
        // loginUserType: action.loginUserType,
      };
    case "KBC_ROUTES":
      console.log("hihihihiii", action.route);
      return {
        ...state,
        route: action.route,
        subRoute: action.subRoute,
      };
    case "BOOK_CREDS":
      // console.log("hihihihiii", action.bookCreds);
      return {
        ...state,
        bookCreds: action.bookCreds,
      };

    case "KATBOOKINITIATION":
      // console.log("hihihihiii", action.katbookInitiation);
      localStorage.setItem("katbookInitiation", action.katbookInitiation);
      return {
        ...state,
        katbookInitiation: action.katbookInitiation,
      };

    case "CREDS":
      return { ...state, creds: action.creds };
    case "BM":
      return { ...state, BookMarkList: action.BookMarkList };
    case "NOTES":
      return { ...state, Notes: action.Notes };
    case "ClarifyID":
      return { ...state, ...action };
    case "LOGOUT":
      localStorage.clear();
      removetranslateCookie()
      // document.cookie = "googtrans=";
      return {
        ...state,
        isAuthenticated: false,
        katbookInitiation: "",
        userType: null,
        user: null,
        email: "",
        _id: "",
        token: null,
        UserTypecode: "",
        route: "",
        subRoute: "",
        institutionName: "",
        section: "",
        userTypeId: "",
        userTypes: "",
        standard: "",
        student: false,
        refInstID: '',
        variableID: '',
        loginUserType: "",
      };
    case "UserLogger":
      return {
        ...state,
        userLogger: !state.userLogger,
      };
    case "LOG":
      // console.log(action);
      return {
        ...state,
        log: action.log,
      };
    case "ScrollFrom":
      // console.log(action);
      return {
        ...state,
        ...action,
      };
    case "CLASS_SELECT":
      return {
        ...state,
        ...action,
      };
    case "STAFF_TYPE":
      return {
        ...state,
        ...action,
      };
    case "Left_fold":
      return {
        ...state,
        ...action,
      };
    case "Right_fold":
        return {
          ...state,
          ...action,
        };
    case "state_color":
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

const App = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [token, setToken] = useState(state.token);
  const [isloading, setIsloading] = useState(false);

  // state = {
  //   language: 'en'
  // }

  // const languageHandler = (e) => {
  //   e.preventDefault();
  //   let lang = e.target.value;
  //   this.setState(prevState => ({
  //     language: lang
  //   }))
  // }

  useEffect(() => {
    const getProfile = async (token, value, secondData) => {
      // alert('getProfile')
      console.log(value, "VA");
      console.log(secondData, "VA");
      console.log(token, "VA");
      if (!token && !value) {
        dispatch({ type: "LOGOUT" });
      } else if (value || secondData.success) {
        var values = JSON.parse(value);
        console.log(values, "Values");
        console.log(secondData, "Values");
        let res;
        try {
          // res = await KampusLogin({
          //   userLoginID: values.userName || secondData.user.username,
          //   password: values.password || secondData.user.code,
          // });
          res = await loginIskconUser({
            userName: values.userName || secondData.user.username,
            password: values.password || secondData.user.code,
          });

          //////////////// START KAMPUS LOGIN DETAILS /////////////////////
          // console.log(res,"KAMPUS LOGIN SUCCESS");
          // if (res.status === "Login Success") {
          //   dispatch({
          //     type: "Profile",
          //     user: res.value.userName,
          //     userType: res.value.userType,
          //     adminName: res.value.adminName,
          //     value: res.value,
          //     token: "",
          //     password: res.value.password,
          //     institutionName: res.value.institutionName,
          //     section: res.value.section,
          //     standard: res.value.standard,
          //     refInstID: res.value.refInstID,
          //     variableID: res.value.variableID,
          //     loginUserType: res.value.userType,
          //     student: res.value.userType === "Teaching" ? false : true,
          //     url: values.url || "",
          //   });
          //   let address = JSON.parse(res.value.kampusAddress);
          //   if (address.State === 'Tamil Nadu') {
          //     dispatch({ type: "state_color", stateColor: "#294d4a" })
          //   }
          //   if (address.State === 'Telangana') {
          //     dispatch({ type: "state_color", stateColor: "#058ED9" })
          //   }
          //   dispatch({ type: "CREDS", creds: res.value });
          //   localStorage.setItem("userCreds", JSON.stringify(res.value));
          //   deleteKampusStudentLogin();
          // } else if (res.status === "failure") {
          //   toast.error(<Danger body={"Session was time out , Please login again!."} />);
          //   dispatch({ type: "LOGOUT" });
          // }
          //////////////// END KAMPUS LOGIN DETAILS /////////////////////
          //////////////// START ISKCON LOGIN DETAILS /////////////////////
          console.log(res,"KAMPUS LOGIN SUCCESS");
          console.log(secondData,"VA");
          if (res.success === true) {
            // alert("success")
            localStorage.setItem("token", res.token);
            await dispatch({
              // ...State,
              type: "LOGIN",
              token: res.token,
              _id: res.user._id,
              email: res.user.email,
              user: res.user.userTypeId.userType === 'ADMIN' ? res.user.authorizedPerson : res.user.fullName,
              userTypeId:res.user.userTypeId._id,
              userType: res.user.userTypeId.userType,
              UserTypecode: res.user.userTypeId.code,
              student: false,
              adminName: res.user.userTypeId.userType === 'ADMIN' ? res.user.authorizedPerson : res.user.fullName,
              institutionName: "ISKCON BANGALORE",
              userName: res.user.userName,
              ageGroup: res.user.ageGroup,
              grade: res.user.userTypeId?.userType === "ADMIN" ? "Admin" : res.user.grade,
              userCode: res.user.password
            });

            
            // dispatch({
            //   type: "Profile",
            //   user: res.user.firstName,
            //   userType: res.user.userTypeId.userType,
            //   adminName: res.user.firstName,
            //   value: res.user,
            //   token: "",
            //   password: "94537228",
            //   institutionName: "ISKCOn",
            //   section: "A",
            //   standard: "",
            //   refInstID: res.value.refInstID,
            //   variableID: res.value.variableID,
            //   loginUserType: res.value.userType,
            //   student: res.value.userType === "Teaching" ? false : true,
            //   url: values.url || "",
            // });
            // let address = JSON.parse(res.value.kampusAddress);
            // if (address.State === 'Tamil Nadu') {
            //   dispatch({ type: "state_color", stateColor: "#294d4a" })
            // }
            // if (address.State === 'Telangana') {
            //   dispatch({ type: "state_color", stateColor: "#058ED9" })
            // }
            // dispatch({ type: "CREDS", creds: res.user });
            // localStorage.setItem("userCreds", JSON.stringify(res.user));
            // deleteKampusStudentLogin();


          } else if (res.success === false) {
            toast.error(<Danger body={"Session was time out , Please close and login again!."} />);
            dispatch({ type: "LOGOUT" });
            // window.close();
          }
          //////////////// END ISKCON LOGIN DETAILS /////////////////////

        } catch (e) {
          // console.log(e,"WWWWWWWWWWWWWWWWWWWWWWWWWWWWWW");
          // alert("ERROR: 2")
          toast.warning(<Warning body={"Something went worng please login!."} />);
          dispatch({ type: "LOGOUT" });
          //window.location = "/";
        }
      }
      setIsloading(false);
      // alert(1)
    };

    const getFuc = async () => {


      // var addres = address.ip();
      // console.log(addres, "ipconfigd");
      var value = "";
      var token = await localStorage.getItem("token");
      // var value = await localStorage.getItem("instDetail");
      console.log(token, "VA");
      // console.log(value, "VA");
      var secondData;
      setIsloading(true);
      // alert(4)
      var datas = String(window.location).replaceAll('%22', '').replaceAll('%20', '').replaceAll("%EF%BB%BF", "");
      if (datas && !value) {
        // alert("qqqqqqqqqq")
        let slenght = datas.search("{");
        let elenght = datas.search("}/");
        if ((elenght !== 0 && slenght !== 0) && (slenght < elenght)) {
          datas = datas.replaceAll('%22', '').replaceAll('%20', '').replaceAll("%EF%BB%BF", "")
          var n = datas.search("username");
          var j = datas.search(",code");
          var k = datas.search("}}");
          console.log(k, "project");
          let user = datas.substr(n, j - n);
          user = user.substr(user.search(":") + 1, user.length);
          let code = datas.substr(j + 1, k - j - 1);
          code = code.substr(code.search(":") + 1, code.length);
          //console.log(JSON.parse(datas), "project");
          if (user && code) {
            value = JSON.stringify({ "userName": user, "password": code });
          }
        }
      }
      console.log(token, "VA");
      console.log(value, "VA");
      console.log(secondData, "VA");
      getProfile(token, value, secondData);
    };
    // setInterval(() => {
    getFuc();
    // }, 2000);
  }, []);


  return (
    <Authcontext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <GlobalContext>
        <EmployeeContext>
          <HashRouter>
            <React.Suspense fallback={loading}>
              {!isloading ? (
                <Switch>
                  {(!state.isAuthenticated) ? (
                    <>
                      <Route
                        exact
                        path="/"
                        name="Login Page"
                        render={(props) => <Login {...props} />}
                      />
                      <Route
                        exact
                        path="/register"
                        name="Register Page"
                        render={(props) => <Register {...props} />}
                      />
                      <Route
                        exact
                        path="/404"
                        name="Page 404"
                        render={(props) => <Page404 {...props} />}
                      />
                      <Route
                        exact
                        path="/500"
                        name="Page 500"
                        render={(props) => <Page500 {...props} />}
                      />
                    </>
                  ) : (
                    state.isAuthenticated && (
                      <Route
                        path="/"
                        name="Home"
                        render={(props) => {
                          //  console.log(props.location.pathname.split('/'),"PROP");

                          if (
                            props.location.pathname
                              .split("/")
                              .includes("BookView") ||  props.location.pathname
                              .split("/")
                              .includes("LinkView")
                          ) {
                            //  console.log(props,"PROP");

                            return <TheContent {...props} style />;
                          }
                          if (
                            props.location.pathname
                              .split("/")
                              .includes("bookList")
                          ) {
                            //  console.log(props,"PROP");

                            return <TheContent {...props} style />;
                          }

                          else {
                            return <TheLayout {...props} />;
                          }
                        }}
                      />
                    )
                  )}
                </Switch>
              ) : (
                <CRow
                  className={"progressBody"}
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <CCol
                    lg="12"
                    style={{ textAlign: "center", marginTop: "15%" }}
                  >

                    <img src={LogoKBIcon} alt="img" /><br /><br />
                    {/* <CSpinner size="lg" variant="grow" /> */}
                    <center><Progress /></center>
                  </CCol>
                </CRow>
              )}
            </React.Suspense>
          </HashRouter>
        </EmployeeContext>
      </GlobalContext>
      <ToastContainer autoClose={3000} />
      <UserLogModel />
    </Authcontext.Provider>
  );
};

export default App;
